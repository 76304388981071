<template>
  <div class="partyBuilding">
    <div class="bannerBox">
      <el-carousel
        indicator-position="inside"
        height="450px"
        class="bannercarousel"
      >
        <el-carousel-item
          v-for="(item,index) in bannerArr"
          :key="index"
        >
          <img
            style="width:100%;height: 400px;"
            :src="`${item.image}`"
            alt=""
          >
        </el-carousel-item>
      </el-carousel>
    </div>
		<div style="margin-top:50px"></div>
    <div class="human-continar">

        <TitleMore
            :title="['重要报道']"
            @handleMore="selfhandleMore('重要报道','ersd_zybd')"
        ></TitleMore>
            <div class="projectactive">
                <div class="projectcontent">
                    <div class="activebanners">
                        <div class="block" >
                            <el-carousel trigger="click" height="480px" >
                                <el-carousel-item v-for="item in imgList" :key="item.id" >
                                    <!-- <h3 class="small">{{ item }}</h3> -->
                                    <img style="width:100%;height:100%" @click="$goto('/detailShow', { id: item.id })" :src="item.smallImage" alt="" />
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="mask" ></div>
                    </div>
                    <div class="activenews">
                        <ul>
                            <li v-for="(item) in newsList" :key="item.id" @click="$goto('/detailShow', { id: item.id })">
                                <p>
                                    <i>{{ item.title }}</i>
                                </p>
                                <span>{{ item.publishDate | formateData }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
		<div style="margin-top:50px"></div>
        <TitleMore
        :title="['学习动态']"
				@handleMore="selfhandleMore('学习动态','ersd_xxdt')"
        ></TitleMore>
        <div class="baseCard">
        <div
            class="itemText"
                style="margin-bottom: 10px;"
            v-for="item in ListData"
            :key="item.id"
            @click="$goto('/detailShow',{id:item.id})"
        >
            <p>
            <img
                src="../../assets/img/list-little.png"
                alt=""
            >
            <span class="itemTextContent">{{item.title}}</span>
            </p>
            <p class="itemTextTime">{{item.publishDate}}</p>
        </div>
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import apiUrls from "@api";
export default {
  components:{
      TitleMore
  },
  props:{},
  data(){
    return {
        bannerArr:[],
          imgList:[],
          newsList:[],
          activelist:[],
      page: 1,
      size: 10,
      total: 0,
      ListData: [],
    }
  },
  watch:{},
  computed:{},
  methods:{
    selfhandleMore(title,node) {
			this.$router.push({
				path: '/imgDetail',
				query: { name: title, type: node }
			});
    },
    handleTab(index){
        switch (index) {
            case 0:
                this.getactivelist('wwqzbss')
                break;
        }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getBanner() {
      apiUrls.homeBanner({ type: "ersd" }).then((res) => {
        this.bannerArr = res.results;
      });
    },
    getList() {
      apiUrls
        .selfDynamicList({ node: "ersd_xxdt", page: this.page - 1, size: this.size })
        .then((res) => {
          this.ListData = res.results.data;
          this.total = res.results.pager.totalElements;
        });
    },
    getactivelist (type) {
        apiUrls.gethumanitarian({node: type}).then(res=>{
            this.activelist= res.results.data;
            this.setData()
        })
    },
    setData(){
        this.$nextTick(function() {
            let _this = this;
            _this.newsList = [];
            _this.imgList = [];
            this.activelist.forEach((item,ind) => {
                //显示所有的新闻
                _this.newsList.push(item)
                if(_this.imgList.length<3&&item.smallImage){
                    _this.imgList.push(item)
                }
            })
        });
    },
  },
  created(){},
  mounted(){
      this.getBanner()
      this.getList()
      this.getactivelist('ersd_zybd')
  }
}
</script>
<style lang="less" scoped>
.bannerBox{
    width: 1200px;
    height: 450px;
    margin: 0px auto 0;
    .bannerCarousel{
      width: 100%;
      height: 100%;
      /deep/ .el-carousel__container{
        height: 100%;
      }
    }
  }
.projectactive {
	box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
}
.human-continar {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;
    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
}
.title {
	margin-top: 53px;
	font-size: 22px;
	color: #333333;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	margin-bottom: 16px;
	span {
		&:nth-child(1) {
			display: flex;
			&::before {
				content: '';
				display: block;
				height: 20px;
				width: 20px;
				background: url('../../assets/img/title.png') no-repeat center center;
			}
		}
		&:nth-child(2) {
			color: #ababab;
			font-size: 17px;
		}
	}
}
.projectcontent {
	padding: 20px;
	// box-sizing: border-box;
	height: 480px;
	display: flex;
	background-color: #ffffff;
	.activebanners {
		
		position: relative;
		width: 646px;
		height: 480px;
		background-color: skyblue;
		.el-carousel__item h3 {
			color: #475669;
			font-size: 14px;
			opacity: 0.75;
			line-height: 150px;
			margin: 0;
		}

		.el-carousel__item:nth-child(2n) {
			background-color: #99a9bf;
		}

		.el-carousel__item:nth-child(2n + 1) {
			background-color: #d3dce6;
		}
		.mask {
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 645px;
			height: 40px;
			// background: #000000;
			opacity: 0.35;
			z-index: 2;
		}
	}
	.activenews {
		flex: 1;
		//    background-color: pink;
		padding: 0 23px;
		li {
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			
			padding: 10px 0;
			margin-bottom: 10px;
			p {
				display: flex;
				color: #333333;
				&:hover {
					color: #d1292e;
				}
				font-size: 16px;
				&::before {
					content: '';
					display: block;
					width: 16px;
					height: 16px;
					background: url('../../assets/img/list-little.png') no-repeat center center;
				}
				i {
					display: inline-block;
					width: 331px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			span {
				color: #ababab;
				font-size: 16px;
			}
		}
	}
}
</style>